<template>
  <div>
    <h2>{{ msg }}</h2>
    <hr class="hr1">
    <nav>
      <router-link :to="{name: 'main'}" teg="button"><button>TO HOME</button></router-link>
    </nav>
    <hr class="hr2">
    <section v-for="door in doors" :key="door.id">
      <div class="x-text">{{ door.tm.toUpperCase() }}</div>
      <h3>{{ door.model }}</h3>
      <div class="flex-divs">
        <div><img :src="require(`../assets/${door.img}`)" :alt="door.model" class="door"></div>
        <div class="descr">{{ door.description }}</div>
      </div>
      <div>{{ door.decor }}</div>
      <div>Можливі розміри: {{ door.size.join(', ') }}</div>
    </section>
  </div>
</template>

<script>
import doorsData from '../doors/doors'
export default {
  data () {
    return {
      msg: 'General catalog page',
      doors: doorsData
    }
  },
  props: {
  }
}
</script>

<style scoped>
  img {
    height: 15rem;
    min-width: 115px;
  }
  .flex-divs {
    display: flex;
    gap: 1rem;
  }
  .flex-divs div img {
    flex-shrink: 0;
    flex-basis: auto;
  }
  .flex-divs .descr  {
    flex-shrink: 1;
    flex-basis: auto;
    border: 1px solid firebrick;
  }
</style>
