import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '../views/main-page'
import generalCatalog from '../components/general-catalog'
import doorTMPage from '../views/door-tm-page'
import errorPage from '../views/404-page'
import selectedDoor from '../components/single-door'

const routes = [
  {
    path: '/',
    name: 'main',
    component: mainPage,
    children: [
      {
        path: '/doors/:tm?',
        name: 'door_tm',
        component: doorTMPage,
        props: (route) => ({
          tm: route.params.tm
          // tmLinks: route.query.tmLinks
        })
      },
      {
        path: '/doors/:tm/:model/:id',
        name: 'door',
        component: selectedDoor
      }

      // {
      //   path: '/product/:id',
      //   redirect: (to) => {
      //     // compute the new URL based on the current route
      //     const { id } = to.params
      //     return `/product/${id}/overview`
      //   }
      // }
    ]
  },
  {
    path: '/all_doors',
    name: 'all_doors',
    component: generalCatalog
  },
  {
    path: '/404',
    name: 'Error page',
    component: errorPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: errorPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

export default router

// {
//   path: '/about',
//   name: 'about',
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
// }
