<template>
  <div id="app">
    <h1>{{ msg.toUpperCase() }}</h1>
    <hr class="hr1">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      msg: 'Choose your doors'
    }
  }
}
</script>

<style>
      * {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }
      body {
        padding: 25px;
        font-size: 16px;
      }
      [v-cloak] {
        display: none;
      }
      h2 {
        color: darkgreen;
      }
      hr{
        margin: 2rem 0;
      }
      .hr1{
        border: 5px solid grey;
        border-radius: 5px;

      }
      .hr2{
        border: 5px dashed rgb(103, 190, 234);
        border-radius: 5px;
      }
      button {
        padding: 8px 15px;
        font-size: 1rem;
        margin: 0.5rem 0;
        box-shadow: inset 0 0 10px 3px gray;
        border: none;
        cursor: pointer;
      }
      input, select {
        display: block;
        padding: 8px 15px;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      input:focus, select:focus {
        border: 1px solid #000;
        outline: none;
        box-shadow: 1px 1px 3px rgb(79, 78, 78);
      }
      .x-text {
        font-weight: bold;
        color: darkorange;
        font-size: 1.5rem;
      }
      #text-container {
        min-height: 2.2rem;
        border: 1px dotted darkgoldenrod;
        width: fit-content;
      }
      li {
        /* list-style: none; */
        margin-bottom: 0.5rem;
        color: darkred;
      }
      .s-text {
        display: inline-block;
        font-weight: 600;
        background-color: darkgreen;
        color: white;
        padding: 0.4rem 0.8rem;
        margin: 0.5rem;
        border: 1px dotted yellowgreen;
        border-radius: 0.3rem;
      }
      table tr td {
        padding: 0 1.5rem;
        border-bottom: 1px dotted grey;
      }
      /* =============================================== */
      .green{
        border: 3px solid green;
        padding: 5px;
      }
      .red{
        background-color: red;
      }
      .active{
        font-size: 30px;
      }
      /* =============================================== */
      a{
        text-decoration: none;
        color: darkgreen;
        display: inline-block;
        padding: 0.4rem 1.5rem;
        border: 1px solid darkmagenta;
        border-radius: 3px;
        box-shadow: 0px 0px 5px grey inset;
        transition: transform 1s ease;
      }
      a:hover{
        transform: translate(0, -5px);
      }
      .flex-items{
        display: flex;
        justify-content: space-around;
      }
      img{
        max-width: 100%;
      }
      .centered {
        border: 1px solid darkmagenta;
        text-align: center;
      }
</style>
