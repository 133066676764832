export default [
  {
    id: 1,
    tm: 'Leador Express',
    model: 'GRADA',
    description: 'Дверне полотно фабрики «КОРФАД», TM «LEADOR Express» модель GRADA, каркасне, стандартне 800*2000, декор - "Клен грей", скло - сатин білий',
    decor: 'Клен грей',
    img: 'grada_klen_grey.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  },
  {
    id: 2,
    tm: 'Leador',
    model: 'MALTA',
    description: 'Дверне полотно фабрики «КОРФАД», TM «LEADOR» модель MALTA, каркасне, стандартне 800*2000, декор - "Монблан", скло - сатин білий',
    decor: 'Монбалн',
    img: 'malta_mb_sn.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  },
  {
    id: 3,
    tm: 'Korfad',
    model: 'PR-01',
    description: 'Дверне полотно фабрики «КОРФАД», серія PORTO, модель PR-01, каркасне, стандартне 800*2000, декор - "Дуб браш", скло - сатин білий',
    decor: 'Дуб браш',
    img: 'pr-01_db.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  },
  {
    id: 4,
    tm: 'Korfad',
    model: 'PR-01',
    description: 'Дверне полотно фабрики «КОРФАД», серія PORTO, модель PR-01, каркасне, стандартне 800*2000, декор - "Дуб тобакко", скло - сатин білий',
    decor: 'Дуб тобакко',
    img: 'pr-01_tb.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  },
  {
    id: 5,
    tm: 'Darumi',
    model: 'PLT-03',
    description: 'Дверне полотно фабрики «КОРФАД», TM «DARUMI», серія PLATO, модель PLT-03, каркасно-щитове, стандартне 800*2000, декор - "Дуб натуральний", молдинг - чорний алюміній',
    decor: 'Дуб натуральний',
    img: 'plt-03_dn.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  },
  {
    id: 6,
    tm: 'Darumi',
    model: 'Versal',
    description: 'Дверне полотно фабрики «КОРФАД», TM «DARUMI», модель VERSAL,каркасне, стандартне 800*2000, декор - "Сірий бетон", скло - сатин білий',
    decor: 'Сірий бетон',
    img: 'versal_bt_sn.jpg',
    size: ['400x2000', '600x2000', '700x2000', '800x2000', '900x2000']
  }
]
