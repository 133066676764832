<template>
  <div>
    <h2>{{ msg }}</h2>
    <hr class="hr1">
    <router-link :to="{name: 'all_doors'}"><button>TO GENERAL CATALOG</button></router-link>
    <hr class="hr2">
    <!-- В linkTMs выбриаем из объекта doors только не повторяющиеся ТМ и здесь далее делам кнопки с маршрутами ровно на это количество ТМ -->

    <!-- 1 Верия - путь объект -->
    <!-- <button v-for="tm in linkTMs" :key="tm.id"
    @click="this.$router.push( { path: '/doors/' + tm } )">{{ tm.toUpperCase() }}</button> -->

    <!-- {tmLinks: 'Leador, Korfad, Darumi, Leador Express' } -->

    <!-- 2 Версия с шаблонной строкой -->
    <!-- <button v-for="tm in linkTMs" :key="tm.id"
    @click="this.$router.push(`/doors/${tm}`)">{{ tm.toUpperCase() }}</button> -->

    <!-- 3 Версия с объектом в кот params and query -->
    <router-link v-for="tm in linkTMs" :key="tm.id"
    :to="{
      name: 'door_tm',
      params: { tm: tm },
      // query: { tmLinks: linkTMs }
    }">
    {{ tm.toUpperCase() }}
    </router-link>
    <hr>
    <router-view></router-view>

  </div>
</template>

<script>
import doors from '../doors/doors'
export default {
  data () {
    return {
      msg: 'This is the main page',
      linkTMs: []
    }
  },
  created () {
    doors.forEach(itm => {
      if (this.linkTMs.indexOf(itm.tm) === -1) {
        return this.linkTMs.push(itm.tm)
      }
    })
  }
}
</script>

<style scoped>
  button {
    margin-right: .5rem;
  }
</style>
