<template>
  <div>
    <h2>Door TM page</h2>
    <hr class="hr1">
    <router-link :to="{ name: 'main' }"><button>HOME</button></router-link>
    <hr class="hr2">
    <p class="x-text">{{ tm }}</p>
    <hr>
    <div class="flex-divs">
      <div v-for="door in currentTm" :key="door.id">
        <router-link :to="{path: `/doors/${door.tm}/${door.model}/${door.id}`}">
          <div class="s-text">{{ door.model }}</div>
          <div><img :src="require(`../assets/${door.img}`)" alt=""></div>
          <div class="txt-decor">{{ door.decor }}</div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import doors from '../doors/doors'
export default {
  data () {
    return {
      currentTm: [],
      tm: this.$route.params.tm
    }
  },
  props: {
    tmLinks: Array
  },
  watch: {
    $route (toRoute) {
      if (toRoute.params.id !== this.tm) {
        this.tm = toRoute.params.tm
      }
    },
    tm (a) {
      // console.log(a)
      this.currentTm = []
      doors.forEach(el => {
        if (a === el.tm) {
          this.currentTm.push(el)
        }
      })
    }
  },
  created () {
    // console.log(this.$route.params)
    doors.forEach(el => {
      if (this.tm === el.tm) {
        this.currentTm.push(el)
      }
    })
  }
}
</script>

<style scoped>
  img {
    height: 15rem;
    min-width: 115px;
  }
  .flex-divs {
    display: flex;
    gap: 1rem;
  }
  .flex-divs div img {
    flex-shrink: 0;
    flex-basis: auto;
  }
  .flex-divs .descr  {
    flex-shrink: 1;
    flex-basis: auto;
    border: 1px solid firebrick;
  }
  .txt-decor {
    font-style: italic;
    text-align: center;
  }
</style>
