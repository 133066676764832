<template>
  <div>
    <hr class="hr1">
    <router-link :to="{name: 'door_tm'}"><button>Back</button></router-link>
    <router-link :to="{ name: 'main' }"><button>HOME</button></router-link>
    <hr class="hr2">
    <h2>Single door page</h2>
    <div v-for="descr in currentDoor" :key="descr.id">
      <div class="x-text">{{ descr.tm }}</div>
      <div class="s-text">{{ descr.model }}</div>
      <div class="flex-divs">
        <div><img :src="require(`../assets/${descr.img}`)" alt=""></div>
        <div>
          <div>{{ descr.description }}</div>
          <hr>
          <div>{{ descr.decor }}</div>
          <hr>
          <div>{{ descr.size.join(', ') }}</div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import doors from '../doors/doors'
export default {
  data () {
    return {
      currentDoor: {},
      currentDoorID: Number,
      doors
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.currentDoor = this.doors.filter(item => item.id === Number(this.$route.params.id))
  }
}
</script>

<style scoped>
  img {
    height: 15rem;
    min-width: 115px;
  }
  .flex-divs {
    display: flex;
    gap: 1rem;
  }
  .flex-divs div img {
    flex-shrink: 0;
    flex-basis: auto;
  }
  .flex-divs .descr  {
    flex-shrink: 1;
    flex-basis: auto;
    border: 1px solid firebrick;
  }
  .txt-decor {
    font-style: italic;
    text-align: center;
  }
</style>
