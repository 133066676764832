<template>
  <div>
    <hr class="hr1">
    <h2>THE PAGE IS NOT FOUND</h2>
    <hr class="hr2">
    <router-link to="/"><button>MAIN PAGE</button></router-link>
  </div>
</template>

<style scoped>
h2 {
  color: brown;
}
</style>
